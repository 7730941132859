<template>
    <div class="surface-card p-6 border-1 surface-border">
        <div class="flex">
            <div class="text-2xl uppercase mb-4">Token</div>
        </div>
        <div>
            <InputText v-model="auth" class="w-full" readonly disabled=""/>
        </div>
    </div>
</template>

<script>
    import auth from "@/plugins/auth";
    import InputText from "primevue/inputtext/InputText";

    export default {
        components: {
            InputText
        },
        data() {
            return {
                auth: '',
            }
        },
        mounted() {
            this.auth = auth.getToken();
        }
    }
</script>
